import React, { useEffect, useState } from "react"
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
    Text
} from '@chakra-ui/react'
import Chart from "chart.js/auto"
import LinearProgress from "./LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import { LuSend } from "react-icons/lu";
import PlusIcon from '../assets/plus-icon.svg';
import CrownImg from '../assets/Crown.png';
import PremiumIcon from '../assets/premium.png';
import NeedleImg from '../assets/needle.svg';
import TotalScoreIcon from '../assets/PerformanceMacbook.png';

import UserNavbar from "./UserNavbar"
import CircularProgress from "./CircularProgress";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import { GetUser } from "app/user/user.action";
import { getDashboardStats } from "app/dashboard/dashboard.action";
import UpdateProfileModal from "./UpdateProfileModal";
import { Buffer } from "buffer";

const UserDashboard = () => {

    const [targetval, setTargetVal] = useState({
        level: "",
        score: 0
    })
    const [isUpdateProfile , setIsUpdateProfile] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dashboard = useSelector((state: any) => state.dashboard);
    const User = useSelector((state: any) => state.user);


    const color = [
        "#CA0000",
        "#FF8000",
        "#FFFF00",
        "#00C061",
        "#54A106",
    ]

    let BackColor = color.slice(0, color.length)
    const data = {
        labels: ['check1', 'check2', 'check3', 'check4', 'check5'],
        datasets: [{
            label: "Amount",
            backgroundColor: BackColor,
            data: ['20', '20', '20', '20', '20']
        },
        ]
    }

    const handleLevel = () => {

        const totalScore = dashboard?.dashboardStats?.totalScore;
        if (totalScore < 60) return "Level 1";
        if (totalScore >= 60 && totalScore < 75) return "Level 2";
        if (totalScore >= 75 && totalScore < 85) return "Level 3";
        if (totalScore >= 85 && totalScore < 90) return "Level 4";
        if (totalScore >= 90) return "Level 5";

        return ""
    }

    const handleRotation = (statVal: string): any => {
        const value: number = dashboard?.dashboardStats?.[statVal];
        if (value === 1) return "-171deg";
        if (value === 2) return "-125deg";
        if (value === 3) return "-58deg";
        if (value === 4) return "8deg";
        if (value === 5) return "60deg";
        return "70deg";
    };

    useEffect(() => {

        const totalScore = dashboard?.dashboardStats?.totalScore;

        if (totalScore < 60) setTargetVal({ level: "Level 2", score: 60 })
        if (totalScore >= 60 && totalScore < 75) setTargetVal({ level: "Level 3", score: 75 })
        if (totalScore >= 75 && totalScore < 85) setTargetVal({ level: "Level 4", score: 85 })
        if (totalScore >= 85 && totalScore < 90) setTargetVal({ level: "Level 5", score: 90 })
        if (totalScore >= 90) setTargetVal({ level: "Level 5", score: 90 })

    }, [dashboard?.dashboardStats?.totalScore])

    useEffect(() => {
        dispatch(GetUser() as any);
        dispatch(getDashboardStats() as any);
    }, [])
    return (
        <>
            <div className="bg-[#F5F5F5] min-h-[100vh]" >
                <UserNavbar />

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    {/* total score */}
                    <div className="w-[50%]" >
                        <h1 className="text-[32px] font-bold flex items-center" >
                            <img src={TotalScoreIcon} alt="icon" className="mr-3 w-10 h-10" />
                            Your Total Score</h1>
                        <div className="w-full flex flex-col md:flex-row items-center justify-between text-white">
                            <div className="w-full flex flex-col md:flex-row md:justify-between py-3">
                                <div className="bg-[#313131] w-[69%] rounded-[15px] flex items-center" >
                                    <div className="mx-5 text-center" >
                                        <div className="flex items-center justify-start" >
                                        {/* score */}
                                        <div className="text-[23px] bg-white rounded-full w-[40px] h-[40px] font-bold my-3" >
                                            <p className="bg-gradient-to-b from-[#507C37] to-[#232323] bg-clip-text text-transparent" >
                                            {dashboard?.dashboardStats?.["totalScore"]}
                                            </p>
                                            </div>
                                        {/* details */}
                                        <div className="text-start" >
                                        <p className="font-semibold mx-2 my-0" >{targetval?.level}</p>
                                        {/* <small>Lorem ipsum dolor sit amet.</small> */}
                                        </div>
                                        </div>
                                        <div>
                                        <LinearProgress
                                            value={(Math.floor(dashboard?.dashboardStats?.["totalScore"]) / 100) * 100}
                                            pathColor="#83FF06"
                                            score={dashboard?.dashboardStats?.["totalScore"]}
                                            text={handleLevel()}
                                            width={300} 
                                            height={20}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center bg-[#313131] w-[30%] p-2 rounded-[15px]" >
                                    <small>Target Score</small>
                                    <h1 className="text-[55px] font-bold bg-gradient-to-b from-[#FFFFFF] to-[#FFF624] bg-clip-text text-transparent" >{targetval?.score}</h1>
                                    <small>{targetval?.level}</small>
                                    <p>How to improve?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-[45%] flex items-center justify-between" >
                        {/* user details */}
                        <div className="text-[18px]" >
                            <h2 className="text-[25px] font-bold">{User?.user?.firstName} {User?.user?.lastName}</h2>
                            <p>{User?.user?.age} years old</p>
                            <p>{User?.user?.gender}</p>
                            <p>Member since {new Date(User?.user?.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</p>
                            <button onClick={() => setIsUpdateProfile(true)} className="mt-4 w-[160px] h-[45px] rounded-[12px] bg-gradient-to-r from-[#507C37] to-[#232323] text-white" >Update Profile</button>
                        </div>

                        <div className="w-[250px] h-full rounded-lg" >
                        {User?.user?.avatar ? (
                                <img
                                    src={User?.user?.avatar}
                                    alt="User Avatar"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' , borderRadius: "12px" }}
                                    
                                />
                            ) : (
                                <div className="flex items-center h-full" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="black"
                                    viewBox="0 0 24 24"
                                    width="150px"
                                >
                                    <path d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 2.4c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" />
                                </svg>
                                </div>
                            )}
                        </div>
                    </div>

                </section>

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Total Energy Expenditure (TEE)</h1>
                        <div className="min-h-[240px] mt-6 pt-3 flex justify-center">
                            <CircularProgress score={Math.floor(dashboard?.dashboardStats?.TEE).toString()} text="Daily Caloric Intake" value={(Math.floor(dashboard?.dashboardStats?.TEE) / 5000) * 100} width={230} height={150} gradiant="bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" pathColor="#54A106" />
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Exercise 5-6 times a week, including intensive cardio and strength training</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Vegetable/Fruit Intake</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]" style={{ transformOrigin: '23% 77%', rotate: `${handleRotation("veg/fruit")}` }} />
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >{dashboard?.dashboardStats?.["veg/fruit"]}</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Maintain daily intake of fruits/vegetables, send client to summary about importance of increasing fruit/vegetable intake for prevention of chronic diseases.</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Fats/Proteins Intake</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]" style={{ transformOrigin: '23% 77%', rotate: `${handleRotation("protien/fat")}` }} />
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >{dashboard?.dashboardStats?.["protien/fat"]}</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[130px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>This is reasonable for someone that is very physically active/athlete and/or has increased needs such as an eledery person (60+) or a pregnant woman</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between min-h-[180px] my-3">
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Processed Foods</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]" style={{ transformOrigin: '23% 77%', rotate: `${handleRotation("processFood")}` }} />
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >{dashboard?.dashboardStats?.["processFood"]}</h1>
                                        <small>Servings</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>This is reasonable for someone that is very physically active/athlete and/or has increased needs such as an eledery person (60+) or a pregnant woman</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Sleep/Stress</h1>
                        <div className="min-h-[240px] mt-6">
                            <div>
                                <div className="w-full relative flex flex-col items-center justify-center">
                                    <DoughnutChart chartData={data} />
                                    <div className="absolute w-full top-[65px] text-center flex flex-col items-center" >
                                        <img src={NeedleImg} alt="" className="ml-14 mt-[0px]" style={{ transformOrigin: '23% 77%', rotate: `${handleRotation("sleep/stress")}` }} />
                                        <h1 className="text-[48px] font-bold text-[#394F2D] mt-[-10px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >{dashboard?.dashboardStats?.["sleep/stress"]}</h1>
                                        <small>hours</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Severe sleep deprivation: guide client to information about toll that lack of sleep has on health and development of chronic diseases, include relaxation and stress management techniques including increased</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white w-[100%] md:w-[32%] rounded-[13px] border-2 border-[#EBEBEB] px-5 py-6 my-3 md:my-0">
                        <h1 className="text-[18px] font-medium text-[#394F2D]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Water /Staying Hydrated</h1>
                        <div className="min-h-[240px] mt-6 pt-4 flex justify-center">
                            <CircularProgress score={dashboard?.dashboardStats?.["water/Hydrated"]} text="Cups" value={(Math.floor(dashboard?.dashboardStats?.["water/Hydrated"]) / 15) * 100} width={230} height={150} pathColor="#FF8000" />
                        </div>
                        <div className="my-3 min-h-[150px]" >
                            <p className="font-medium flex items-center  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent " >Goal <img src={CrownImg} alt="crown-img" /> </p>
                            <p>Educate client about the core importance of staying well hydrated, toll it takes on health if not.</p>
                        </div>
                        <div className="mt-4">
                            <p className="text-center font-bold my-2  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Consult with our AI Agents</p>
                            <div className="flex items-center justify-center w-full">
                                <div onClick={() => navigate("/user/chat")} className="w-full flex justify-center items-center border-2 border-[#EBEBEB] px-4 py-3 rounded-[13px]  bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent font-bold cursor-pointer" ><small >Chat with Live100 AI</small> <LuSend className="mx-2 text-[#4B7135]" /> </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sm:py-5 md:py-4 px-0 md:px-20 w-full my-3">
                  
                </section>

                <footer className="bg-white py-10" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                        <Link to="" className="mx-2">About Us</Link>
                        <Link to="" className="mx-2">Privacy</Link>
                        <Link to="" className="mx-2">Terms Of Use</Link>
                        <Link to="" className="mx-2">Contact Us</Link>
                        <Link to="" className="mx-2">Write a Feedback</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>


            </div>

            {/* update Profile modal */}
            <UpdateProfileModal isOpen={isUpdateProfile} onClose={() => setIsUpdateProfile(false)} />
        </>
    )
}

export default UserDashboard
