import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";

import { Country, State } from 'country-state-city';
import { GetUser, UpdateProfile } from 'app/user/user.action';

import { ClipLoader } from 'react-spinners';

interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const EditProfileModal: React.FC<SignupModalProps> = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const User = useSelector((state: any) => state.user);

    const [inspiration, setInspiration] = useState([]);
    const [inputs, setInputs] = useState<string[]>(["", "", "", ""]);

    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');

    const [city , setCity] = useState("");
    const [postalCode , setPostalCode] = useState("");
    const [title , setTitle] = useState("");
    const [about , setAbout] = useState("");
    const [reason , setReason] = useState("");

    const countries = Country.getAllCountries();
    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountry(event.target.value);
        setSelectedState('');
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(event.target.value);
    };

    const handleInputChange = (value: string, index: number) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
        setInspiration(updatedInputs)
    };

    const handleAddFields = () => {
        setInputs([...inputs , ""])
    }

    const handleUpdateProfile = () => {
        const country = countries.find((country) => country.isoCode === selectedCountry)
        const state = states.find((state) => state.isoCode === selectedState);

        dispatch(UpdateProfile({location:country?.name , state:state?.name, city , postalCode , title, about , reason , inspiration}))
        dispatch(GetUser());
    }

    useEffect(() => {
        if(User?.user){
            setAbout(User?.user?.about)
            setTitle(User?.user?.title)
            setReason(User?.user?.reasonOfShape)
            setCity(User?.user?.city)
            setPostalCode(User?.user?.postalCode)
            console.log(User?.user?.inspirations)
            setInputs(User?.user?.inspirations)
            if(User?.user?.location){
                const countryName = User?.user?.location;
                const country = countries.find((c) => c.name === countryName);
                setSelectedCountry(country?.isoCode) 
            }
        }
    },[User?.user])

    useEffect(() => {
        if(User?.user?.state){
            const stateName = User?.user?.state;
            const state = states?.find((s) => s.name === stateName);
            console.log(state)
            setSelectedState(state?.isoCode)
        }
    },[User?.user , selectedCountry])

    useEffect(() => {
        console.log(inputs)
    },[inspiration , inputs])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="1120px" width="100%" padding="30px">
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full py-10" >
                            <div className="flex flex-col items-start w-full">
                                {/* location */}
                                <div className="w-full flex items-start justify-between border-b-2 pb-4" >
                                    <div className="w-[60%] rounded-lg" >
                                        <h2 className="text-[20px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">Edit Your Profile</h2>

                                        <div className='my-3' >
                                            <h2 className="text-[18px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">Your Location</h2>
                                        </div>

                                        <div className='my-2 w-full' >
                                           <div className="w-full flex items-center justify-between my-3">
                                            <label className="w-[30%]">Location:</label>
                                            <select
                                                className="w-[65%] border-2 border-[#E2E2E2] rounded-md h-[53px] px-3"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                            >
                                                <option value="">Select a Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.isoCode} value={country.isoCode}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-full flex items-center justify-between my-3">
                                            <label className="w-[30%]">State/Region:</label>
                                            <select
                                                className="w-[65%] border-2 border-[#E2E2E2] rounded-md h-[53px] px-3"
                                                value={selectedState}
                                                onChange={handleStateChange}
                                                disabled={!states.length}
                                            >
                                                <option value="">Select a State/Region</option>
                                                {states.map((state) => (
                                                    <option key={state.isoCode} value={state.isoCode}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                            <div className="w-full flex items-center justify-between my-3" >
                                                <label className='w-[30%]' >City:</label>
                                                <input type="text" placeholder='Enter city' value={city} onChange={(e:any) => setCity(e.target.value)} className='w-[65%] h-[53px] px-3 border-2 border-[#E2E2E2] rounded-md' />
                                            </div>
                                            <div className="w-full flex items-center justify-between my-3" >
                                                <label className='w-[30%]' >Zip/Postal Code:</label>
                                                <input type="text" placeholder='Enter postal code' value={postalCode} onChange={(e:any) => setPostalCode(e.target.value)} className='w-[65%] h-[53px] px-3 border-2 border-[#E2E2E2] rounded-md' />
                                            </div>
                                        </div>
                                    </div>



                                    {/* user details */}
                                    <div className="w-[39%] mx-10 ">
                                        <h2 className=" font-bold mb-3">Why Should I Provide My Location?</h2>
                                        <p>We use your location to provide data consents specific to your location.</p>
                                    </div>
                                </div>
                                {/* about you */}
                                <div className="w-full flex items-center justify-between pb-4 my-4" >
                                    <div className="w-[60%] rounded-lg" >
                                        <h2 className="text-[20px] font-bold bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent">About You</h2>

                                        <div className='my-2 w-full' >
                                            <div className="w-full my-3" >
                                                <label className='font-semibold' >Your Page Title: <span className='font-medium' >(e.g. "Mom on a mission", "It's time to change...")</span></label>
                                                <input type="text" value={title} onChange={(e:any) => setTitle(e.target.value)} className='w-full border-2 my-1 border-[#E2E2E2] rounded-md h-[53px] px-3' />
                                            </div>

                                            <div className="w-full my-3" >
                                                <label>About Me:</label>
                                                <textarea value={about} onChange={(e:any) => setAbout(e.target.value)} className='w-full border-2 my-1 border-[#E2E2E2] rounded-md h-[131px] px-3'></textarea>
                                            </div>


                                            <div className="w-full my-3" >
                                                <label>Why I want to get in shape:</label>
                                                <textarea value={reason} onChange={(e:any) => setReason(e.target.value)} className='w-full border-2 my-1 border-[#E2E2E2] rounded-md h-[131px] px-3'></textarea>
                                            </div>

                                            {/* inspiration */}
                                            <div className="w-full my-3">
                                                <label className='flex items-center justify-between' >My Inspirations For Getting In Shape: <span><AiFillPlusCircle onClick={handleAddFields} className='text-[20px] text-[#232323] cursor-pointer'  /></span></label>
                                                {inputs?.map((inputValue, index) => (
                                                    <div className="relative my-2" key={index}>
                                                        <input
                                                            type="text"
                                                            className="w-full border-2 border-[#E2E2E2] rounded-md h-[53px] px-3"
                                                            value={inputValue}
                                                            onChange={(e) => handleInputChange(e.target.value, index)}
                                                        />
                                                        {/* <div
                                                            className="absolute right-2 top-4 bg-gradient-to-r from-[#507C37] to-[#232323] rounded-md p-1 cursor-pointer"
                                                            onClick={() => handleAddInspiration(index)}
                                                        >
                                                            <AiFillCheckCircle className="text-[20px] text-white" />
                                                        </div> */}
                                                    </div>
                                                ))}
                                            </div>

                                            <button onClick={handleUpdateProfile} className='w-full bg-gradient-to-r from-[#232323] to-[#507C37] text-white text-center mt-3 h-[45px] rounded-[12px]' >
                                            {
                                                    User?.loading ?
                                                    <ClipLoader color="white" size={22} />
                                                    :
                                                    "Save Changes"    
                                                }
                                            </button>

                                        </div>
                                    </div>



                                    {/* user details */}
                                    <div className='w-[39%]' >
                                        <div className=" mx-10 my-4">
                                            <h2 className=" font-bold mb-3">Topics you might talk about:</h2>
                                            <ul className='list-disc' >
                                                <li>Have you tried to lose weight before?</li>
                                                <li>Why did you join Live100?</li>
                                                <li>Tell us about your family</li>
                                            </ul>
                                        </div>

                                        <div className="mx-10 my-10">
                                            <h2 className=" font-bold mb-3">Other members wrote:</h2>
                                            <ul className='list-disc' >
                                                <li>To be a good example for my family</li>
                                                <li>To feel healthier and look healthier</li>
                                                <li>To fit into my old jeans</li>
                                            </ul>
                                        </div>


                                        <div className="mx-10 my-4">
                                            <h2 className=" font-bold mb-3">Other members wrote:</h2>
                                            <ul className='list-disc' >
                                                <li>Living a long, healthy life</li>
                                                <li>My skinny wardrobe going to waste</li>
                                                <li>My wife - she lost 50 lbs!</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditProfileModal;
